import Vue from 'vue';
import Loading from '@/components/loading.vue';
 
const LoadingConstructor = Vue.extend(Loading);
const loadingInstance = new LoadingConstructor({
  el: document.createElement('div'),
  propsData: {}
});
 
document.body.appendChild(loadingInstance.$el);
 
// 在Vue原型上添加方法
Vue.prototype.$loading = {
  show: function() {
    loadingInstance.show();
  },
  hide: function() {
    loadingInstance.hide();
  }
};