<template>
  <div class="guard">
    <!-- <div class="guard-bg"></div> -->
    <!-- 标题 -->
    <div class="guard-title flex cen">
      <div
        v-show="server_mode == 'local' || server_mode == 'cloud'"
        @click="authPopup()"
        class="contentMsg"
      >
        {{ contentMsg }}
      </div>
      <div class="flex dir-c alic">
        <p>总览</p>
      </div>
    </div>
    <!-- 内容 -->
    <div class="guard-content">
      <div class="every">
        <div class="every-box">
          <div class="every-left">
            <div class="card1-top">
              <div class="card1-left">
                <div class="card1-img">
                  <img
                    class="detail-icon"
                    :src="require('@/assets/imgs/guard/guard_device.png')"
                    alt=""
                  />
                </div>
              </div>
              <div class="card1-right">
                <p class="right-num">{{ total }}</p>
                <p class="right-text">设备总数</p>
              </div>
            </div>
            <p class="card1-bottom">{{ updateTime }} 更新</p>
          </div>
          <div class="every-right" @click="openDialog()">
            <van-circle
              v-model="severityScore"
              :rate="severityScore"
              :speed="100"
              :stroke-width="100"
              layer-color="#EDF1F4"
            >
              <template slot="default">
                <div class="circle">
                  <p class="cate-word">
                    <span class="cate-num">{{ severityScore || 0 }}</span
                    >分
                  </p>
                  <p class="cate">评测规则</p>
                </div>
              </template>
            </van-circle>
          </div>
        </div>
      </div>
      <div class="every">
        <div class="flex jusc-between every-top">
          <div class="every-title flex alic">
            <p>漏洞设备</p>
          </div>
          <div
            style="flex-shrink: 0"
            class="flex alic"
            @click="toOther('/loophole')"
          >
            <span style="color: #9ba1b6">查看明细</span>
            <img
              class="detail-icon"
              :src="require('@/assets/imgs/detail-icon.png')"
              alt=""
            />
          </div>
        </div>
        <div class="flex alic every-bottom">
          <div class="devices-box">
            <p class="num">{{ vulnerableDevices.high }}</p>
            <p class="text">高危设备</p>
          </div>
          <div class="devices-box">
            <p class="num">{{ vulnerableDevices.medium }}</p>
            <p class="text">中危设备</p>
          </div>
          <div class="devices-box">
            <p class="num">{{ vulnerableDevices.low }}</p>
            <p class="text">低危设备</p>
          </div>
        </div>
      </div>
      <div class="every" v-for="(item, index) in guardList" :key="index">
        <div class="flex jusc-between every-top">
          <div class="every-title flex alic">
            <p>{{ item.title }}</p>
          </div>
          <div
            style="flex-shrink: 0"
            class="flex alic"
            @click="toOther('/risk')"
          >
            <span style="color: #9ba1b6">查看明细</span>
            <img
              class="detail-icon"
              :src="require('@/assets/imgs/detail-icon.png')"
              alt=""
            />
          </div>
        </div>
        <div class="flex alic every-bottom">
          <div class="bottom-body">
            <div class="bottom-box" v-for="(it, id) in item.children" :key="id">
              <img :src="it.img" alt="" height="36px" />
              <div class="bottom-text">
                <p class="bottom-word">
                  <span class="bottom-num">{{ it.num }}</span
                  >{{ it.remark }}
                </p>
                <p class="bottom-title">{{ it.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-dialog
      v-model="dialogVisible"
      round-button
      confirm-button-text="我知道了"
    >
      <template slot="title">
        <div class="dialog-b-title">可以从以下维度，提升评分</div>
      </template>
      <template slot="footer">
        <div class="dialog-b-title">可以从以下维度，提升评分</div>
      </template>
      <div class="dialog-box" v-for="(item, index) in dialogList" :key="index">
        <div class="dialog-img" :style="{ backgroundColor: item.bgColor }">
          <img :src="item.img" />
        </div>
        <div class="dialog-right">
          <p class="dialog-title">{{ item.title }}</p>
          <p class="dialog-text">{{ item.text }}</p>
        </div>
      </div>
    </van-dialog>
    <authPopup ref="authPopup"></authPopup>
  </div>
</template>

<script>
import api_index from "@/api/guard.js";
import bus from "@/utils/bus.js";
import { Toast } from "vant";
export default {
  name: "Guard",
  data() {
    return {
      dialogVisible: false,
      dialogList: [
        {
          img: require("@/assets/imgs/guard/loudong.png"),
          title: "设备漏洞",
          text: "设备存在漏洞，请及时修复",
          bgColor: "#eeebfd",
        },
        {
          img: require("@/assets/imgs/guard/eyi.png"),
          title: "网络攻击",
          text: "设备被黑客攻击，请修复或加固防护",
          bgColor: "#fff5e6",
        },
        {
          img: require("@/assets/imgs/guard/fengxian.png"),
          title: "风险上网行为",
          text: "员工上网行为存在危险行为，请提高全员防诈及安全意识。",
          bgColor: "#ddf4fe",
        },
      ],
      guardList: [
        {
          title: "网络风险设备",
          children: [
            {
              img: require("@/assets/imgs/guard/muma.png"),
              name: "木马病毒",
              num: 0,
              remark: "设备",
            },
            {
              img: require("@/assets/imgs/guard/eyi.png"),
              name: "恶意软件",
              num: 0,
              remark: "设备",
            },
            {
              img: require("@/assets/imgs/guard/wakuang.png"),
              name: "挖矿风险",
              num: 0,
              remark: "设备",
            },
            {
              img: require("@/assets/imgs/guard/loudong.png"),
              name: "漏洞利用",
              num: 0,
              remark: "设备",
            },
          ],
        },
        // {
        //   title: '网络上网行为',
        //   children: [
        //     {
        //       img: require('@/assets/imgs/guard/zhapian.png'),
        //       name: '诈骗风险',
        //       num: 232,
        //       remark: '次风险',
        //     },
        //     {
        //       img: require('@/assets/imgs/guard/fengxian.png'),
        //       name: '风险上网',
        //       num: 832,
        //       remark: '次风险',
        //     },
        //   ],
        // },
      ],
      //漏洞设备
      vulnerableDevices: {
        high: 0,
        medium: 0,
        low: 0,
      },
      severityScore: 0,
      total: 0,
      updateTime: "2024-02-21 23:32",
      server_mode: "",
      contentMsg: "",
    };
  },
  components: {
    authPopup: () => import("@/components/auth-popup.vue"),
  },
  computed: {},
  watch: {},
  mounted() {
    //修改左上角文案
    bus.$on("changeContentMsg", (msg) => {
      this.contentMsg = msg;
    });
    this.login();
  },
  methods: {
    async authPopup() {
      let that = this;
      if (this.server_mode == "local") {
        //本地
        this.$refs.authPopup.init1(this.contentMsg);
      } else {
        //云端，云端需要跳转到本地再进行设置
        let res = await api_index["get_local_address"]({
          organization: JSON.parse(window.localStorage.getItem("companyInfo"))
            .organization,
        });
        if (res && res.error == "0") {
          that.$loading.show();
          $.ajax({
            type: "get",
            cache: false, //true的话会读缓存,第二次的时候得到的是上次访问的结果，而不是重新到服务器获取。false的话会在url后面加一个时间缀，让它跑到服务器获取结果。cache只有GET方式的时候有效。
            url: res.result.location, //url地址
            dataType: "jsonp", //跨域采用jsonp方式
            processData: false, //processData 默认为true，当设置为true的时候,jquery ajax 提交的时候不会序列化 data，而是直接使用data，false会序列化。
            timeout: 5000, //设置超时 ‘0’：为永不超时，当请求超时后会进入error，可以在error中做超时的处理。
            complete: function (data) {
              console.log("data", data);
              //data.status 请求url地址的状态码，以此来判断url是否有效可以访问。
              that.$loading.hide();
              if (data.status == 200) {
                console.log("有效访问");
                window.location.href = `${
                  res.result.location
                }#/?token=${window.localStorage.getItem(
                  "token"
                )}&organization=${
                  JSON.parse(window.localStorage.getItem("companyInfo"))
                    .organization
                }`;
              } else {
                console.log("无效访问");
                that.$router
                  .replace(
                    `/404?token=${window.localStorage.getItem(
                      "token"
                    )}&location=${res.result.location}`
                  )
                  .catch((e) => {});
              }
            },
            error: function () {},
          });
        }
      }
    },
    async login() {
      try {
        let token = window.localStorage.getItem("token");
        if (!token) return;
        let res = await api_index["app_login"](token, {
          organization: this.$route.query.organization || '',
        });
        if (res && res.error == "0") {
          window.localStorage.setItem(
            "companyInfo",
            JSON.stringify({
              organization: res.result.organization,
              organization_name: res.result.organization_name,
            })
          );
          this.getDataList();
        }
      } catch (err) {
        console.log("err", err);
      }
    },
    async getDataList() {
      try {
        let res = await api_index["homepageSummary"]({
          organization: JSON.parse(window.localStorage.getItem("companyInfo"))
            .organization,
        });
        if (res) {
          let json = {
            "-1": "待配置",
            0: "仅企业内",
            1: "云端同步",
          };
          this.server_mode = res.server_mode || "";
          this.contentMsg = json[res.data_sync] || "";
          if (res.error == "0") {
            if (res.result) {
              let data = res.result;
              this.total = data.terminals_total_num || 0;
              this.severityScore = data.severity_score || 0;
              this.updateTime = data.update_time || "2024-02-21 23:32";
              this.vulnerableDevices = {
                high:
                  data.vulnerable_terminal_summary_info
                    .high_risk_terminals_num || 0,
                medium:
                  data.vulnerable_terminal_summary_info
                    .medium_risk_terminals_num || 0,
                low:
                  data.vulnerable_terminal_summary_info
                    .low_risk_terminals_num || 0,
              };
              let wlaq = data.network_risk_terminal_summary_info;
              this.guardList[0].children[0].num =
                wlaq.trojan_virus_terminals_num || 0;
              this.guardList[0].children[1].num =
                wlaq.malware_infected_terminals_num || 0;
              this.guardList[0].children[2].num =
                wlaq.mining_risk_terminals_num || 0;
              this.guardList[0].children[3].num =
                wlaq.exploited_terminals_num || 0;
            }
          }
        }
      } catch (err) {
        console.log("err", err);
      }
    },
    openDialog() {
      this.dialogVisible = true;
    },
    toOther(path) {
      this.$router.push({
        path: path,
        query: {},
      });
    },
  },
};
</script>

<style lang="less" scoped>
.guard {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  font-size: 14px;
  height: 100vh;
  background: linear-gradient(180deg, #c4dfff 0%, #ffffff 30%);
  .guard-title {
    width: 100vw;
    height: 44px;
    background: rgba(255, 255, 255, 0);
    position: relative;
    .contentMsg {
      position: absolute;
      left: 14px;
      padding: 3px 10px;
      background: rgba(53, 115, 244, 0.39);
      border-radius: 50px;
      font-size: 12px;
      color: #48547c;
      line-height: 17px;
    }
    > div {
      p:nth-of-type(1) {
        font-weight: 600;
        font-size: 18px;
        color: #141e41;
      }
      p:nth-of-type(2) {
        font-weight: 400;
        font-size: 10px;
        color: #9ba1b6;
      }
    }
  }
  .guard-content {
    padding: 0 16px 16px;
    .every {
      margin-top: 12px;
      padding: 16px 16px 13px;
      background: rgb(255, 255, 255);
      box-shadow: 0px 1px 12px rgba(187, 201, 215, 0.2);
      border-radius: 8px;
      .every-top {
        padding-bottom: 16px;
        .detail-icon {
          width: 14px;
          height: 14px;
          margin-left: 4px;
        }
        .every-title {
          flex: 1;
          > p:nth-of-type(1) {
            font-weight: 600;
            font-size: 16px;
            color: #141e41;
            margin-left: 8px;
            padding-right: 5px;
          }
        }
      }
      .every-bottom {
        .bottom-body {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          .bottom-box {
            width: 50%;
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            > img {
              height: 48px;
            }
            .bottom-text {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              margin-left: 5px;
              .bottom-word {
                color: #bbc9d7;
                margin-bottom: 5px;
                font-size: 14px;
                .bottom-num {
                  color: #3573f4;
                  font-size: 26px;
                  font-weight: bold;
                  margin-right: 4px;
                }
              }
              .bottom-title {
                color: #141e41;
              }
            }
          }
        }
        > .devices-box:nth-of-type(2) {
          border-left: 1px solid #e5e5e5;
          border-right: 1px solid #e5e5e5;
        }
        .devices-box {
          width: calc(100% / 3);
          padding: 0 10px;
          .num {
            font-weight: bold;
            font-size: 24px;
          }
          .text {
            color: #9ba1b6;
            margin-top: 5px;
          }
        }
      }
      .every-box {
        display: flex;
        margin: 10px 0;
        .every-left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 50%;
          border-right: 1px solid #e5e5e5;
          .card1-top {
            display: flex;
            align-items: center;
            .card1-left {
              width: 50%;
              display: flex;
              justify-content: center;
              .card1-img {
                width: 48px;
                height: 48px;
                background: rgba(53, 115, 244, 0.39);
                display: flex;
                border-radius: 16px;
                align-items: center;
                justify-content: center;
                > img {
                  height: 30px;
                }
              }
            }
            .card1-right {
              width: 50%;
              .right-num {
                color: #3573f4;
                font-size: 26px;
                font-weight: bold;
              }
              .right-text {
                color: #48547c;
              }
            }
          }
          .card1-bottom {
            font-size: 12px;
            display: flex;
            justify-content: center;
            margin-top: 10px;
            color: #9ba1b6;
          }
        }
        .every-right {
          display: flex;
          width: 50%;
          justify-content: center;
          .circle {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            flex-direction: column;
            .cate-word {
              color: #3573f4;
              .cate-num {
                font-size: 26px;
                margin-right: 2px;
              }
            }
            .cate {
              color: #48547c;
            }
          }
        }
      }
    }
  }
}
.dialog-b-title {
  display: flex;
  margin: 0 20px;
  font-weight: bold;
}
.dialog-box {
  display: flex;
  align-items: center;
  margin: 20px 30px 10px;
  .dialog-img {
    width: 48px;
    height: 48px;
    display: flex;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    > img {
      height: 30px;
    }
  }
  .dialog-right {
    width: calc(100% - 53px);
    .dialog-title {
      color: #141e41;
      font-size: 18px;
    }
    .dialog-text {
      color: #9ba1b6;
    }
  }
}
/deep/ .van-dialog {
  .van-button {
    margin: 20px 0;
    .van-button__content {
      width: 70%;
      margin: 0 auto;
      background: #3573f4;
      border-radius: 500px;
      color: #ffffff;
    }
  }
}
</style>
