// 配置路由相关的信息
import VueRouter from 'vue-router'
import Vue from 'vue'

import guard from '@/views/guard'

// 1.通过Vue.use(插件), 安装插件
Vue.use(VueRouter)

// 2.创建VueRouter对象
const routes = [
  {
    path: "/",
    name: "Guard",
    component: guard,
  },
  {
    path: "/loophole",
    name: "Loophole",
    component: ()=> import('@/views/loophole.vue')
  },
  {
    path: "/risk",
    name: "Risk",
    component: ()=> import('@/views/risk.vue')
  },
  {
    path: "/404",
    name: "NetworkError",
    component: ()=> import('@/views/network-error.vue')
  },
]
// const router = new VueRouter({
//   // 配置路由和组件之间的应用关系
//   routes,
//   mode: 'hash',
//   linkActiveClass: 'active'
// })

// // 3.将router对象传入到Vue实例
// export default router

// 3. 创建 router 实例，然后传 `routes` 配置
// 你还可以传别的配置参数, 不过先这么简单着吧。
const router = new VueRouter({
  routes // (缩写) 相当于 routes: routes
})
 
// 4. 创建和挂载根实例。(在main.js里继续挂载)
// 记得要通过 router 配置参数注入路由，
// 从而让整个应用都有路由功能
 
// 解决重复点击导航时，控制台出现报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
 
export default router
