import httpRequest from "@/utils/httpRequest";
export default {
    //认证接口
    app_login(token,o) {
        return httpRequest({
            url: "/appapi/v1/app_login",
            method: "get",
            params: o,
            headers: {
                "token": token
            }
        });
    },
    //漏洞设备列表信息接口
    homepageSummary(o) {
        return httpRequest({
            url: "/appapi/v1/homepage_summary",
            method: "get",
            params: o,
        });
    },
    //用户配置是否允许同步数据到云端
    config_data_sync(o) {
        return httpRequest({
            url: "/appapi/v1/config_data_sync",
            method: "get",
            params: o,
        });
    },
    //获取本地h5网址
    get_local_address(o) {
        return httpRequest({
            url: "/appapi/v1/get_local_address",
            method: "get",
            params: o,
        });
    },
};
