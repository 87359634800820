import axios from "axios";
import qs from "qs";
import router from "@/router";
import { Dialog } from 'vant';
import debounce from "lodash/debounce";
import bus from '@/utils/bus.js';
import Vue from 'vue'
const showErrorMsg = debounce(msg => Dialog.alert({
  message: msg,
  confirmButtonColor: '#3573F4',
}).then(() => {
}));

const errorMessages = res => `${res.status} ${res.statusText}`;

const http = axios.create({
  timeout: '',
  withCredentials: true
});



/**
 * 请求拦截
 */
http.interceptors.request.use(
  conf => {
    // 生成取消请求的 token/cancel
    conf.cancelToken = new axios.CancelToken(cancel => {
      /**
       * conf.cancel 是自定义回调函数，必要时页面可以通过回调拿到单个token，取消单个请求
       * 示例：请求时传入与url同级的参数 cancel：cancel => (this.getListCancel = cancel)
       * 调用 this.getListCancel && this.getListCancel(); 取消上次请求
       * 注意 getListCancel 需要定义在实例上（如组件的data中），而不是api中，避免一个页面请求两个相同的api时，前一个请求被取消
       */
      if (conf.cancel) conf.cancel(cancel);
      /**
       * 之前为每个页面的所有请求生成一个token，取消所有请求时一次取消，但不能满足对单个请求取消的场景
       * 所以改成为每个请求生成一个token，用数组来保存，取消所有请求时遍历取消
       */
      //   window.globalCache.requestCancels =
      //     window.globalCache.requestCancels || [];
      //   window.globalCache.requestCancels.push(cancel);
    });

    // get 请求加上随机数
    if (conf.method == "get") {
      conf.params = {
        t: new Date().getTime(),
        ...conf.params
      };
    }

    // 请求头带上token
    // conf.headers['Authorization'] = Vue.cookie.get("token");

    // conf.headers['E-Auth-App-Key'] = 'dial-test';
    // url 转换
    /* conf.url = (process.env.NODE_ENV !== "production" && process.env.OPEN_PROXY
      ? "/proxyApi"
      : "") + conf.url; */
    if (!conf.baseURL) {
      conf.baseURL = '/service';
    }

    if (
      conf.headers["Content-Type"] &&
      conf.headers["Content-Type"].indexOf(
        "application/x-www-form-urlencoded"
      ) != -1
    ) {
      conf.data = qs.stringify(conf.data);
    }

    return conf;
  },
  error => {
    return Promise.reject(error);
  }
);

/**
 * 响应拦截
 */
http.interceptors.response.use(
  res => {
    if (res && res.data && res.data.error == '10001') {//首次登录
      router.replace(`/?token=${window.localStorage.getItem('token')}`).catch(e => { });
      bus.$emit('changeStatus', { status: 10001 });
    }
    if (res && res.data && res.data.error == '10002' && res.data.result.location) {//需要跳转到本地的前端
      Vue.prototype.$loading.show()
      $.ajax({
        type: 'get',
        cache: false,//true的话会读缓存,第二次的时候得到的是上次访问的结果，而不是重新到服务器获取。false的话会在url后面加一个时间缀，让它跑到服务器获取结果。cache只有GET方式的时候有效。
        url: res.data.result.location, //url地址
        dataType: "jsonp", //跨域采用jsonp方式
        processData: false,//processData 默认为true，当设置为true的时候,jquery ajax 提交的时候不会序列化 data，而是直接使用data，false会序列化。
        timeout: 5000,//设置超时 ‘0’：为永不超时，当请求超时后会进入error，可以在error中做超时的处理。
        complete: function (data) {
          console.log('data', data);
          //data.status 请求url地址的状态码，以此来判断url是否有效可以访问。
          Vue.prototype.$loading.hide()
          if (data.status == 200) {
            console.log('有效访问');
            window.location.href = `${res.data.result.location}#/?token=${window.localStorage.getItem("token")}&organization=${JSON.parse(window.localStorage.getItem("companyInfo"))
            .organization}`
          } else {
            console.log('无效访问');
            router.replace(`/404?token=${window.localStorage.getItem('token')}&location=${res.data.result.location}`).catch(e => { });
          }
        },
        error: function () { }
      });
    }
    return res.data;
  },
  err => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = "请求错误(400)";
          break;
        case 401:
          err.message = "未授权，请重新登录(401)";
          break;
        case 403:
          err.message = "拒绝访问(403)";
          break;
        case 404:
          err.message = "请求出错(404)";
          break;
        case 408:
          err.message = "请求超时(408)";
          break;
        case 500:
          err.message = "服务器错误(500)";
          break;
        case 501:
          err.message = "服务未实现(501)";
          break;
        case 502:
          err.message = "网络错误(502)";
          break;
        case 503:
          err.message = "服务不可用(503)";
          break;
        case 504:
          err.message = "网络超时(504)";
          break;
        case 505:
          err.message = "HTTP版本不受支持(505)";
          break;
        default:
          err.message = `连接出错(${err.response.status})!`;
      }

      showErrorMsg(err.message);
    } else {
      // 可能是请求被取消，这时不需要进行提示
      // err.message = "连接服务器失败!";
    }

    return Promise.reject(err);
  }
);

export default http;
