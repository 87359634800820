import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/css/common.css'
//适配移动端
import 'amfe-flexible';
import Vant from 'vant';
import 'vant/lib/index.css';
import getUrlParams from "@/utils/getUrlParams.js";

import '@/utils/loading.js'
let params = getUrlParams(window.location.href);
if (params.token) {
  window.localStorage.setItem("token", params.token);
}
// console.log('token', window.localStorage.getItem("token"));
Vue.use(Vant)
// Vue.use($loading)
// 关闭生产提示
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  // 使用路由
  router
}).$mount('#app')
