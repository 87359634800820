<template>
  <div v-if="isLoading" class="loading-overlay">
    <div class="loading-content">
      <div class="titlt-box flex jusc-between alic">
        <p class="title">欢迎来到安全助手！</p>
        <img class="robot" :src="require('@/assets/imgs/robot.png')" alt="" />
      </div>
      <div class="content flex dir-c alic">
        <p>正在连接企业内网</p>
        <p>请将网络连接至企业内网呦</p>
        <div class="loading-div flex cen">
          <img
            class="loading-gif"
            :src="require('@/assets/imgs/loading.gif')"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  name: "Loading",
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    show() {
      this.isLoading = true;
    },
    hide() {
      this.isLoading = false;
    },
  },
};
</script>
 
<style lang="less" scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.loading-content {
  width: 80%;
  background-color: #ffffff;
  z-index: 1001;
  margin: auto;
  border-radius: 16px;
  box-shadow: 0px 1px 12px rgba(187, 201, 215, 0.2);
  border-radius: 16px;
  padding-bottom: 30px;
  .titlt-box {
    margin-bottom: 16px;
    border-bottom: 1px solid rgba(229, 229, 229);
    padding: 12px 26px 0 18px;
    .title {
      font-weight: 550;
      font-size: 18px;
      color: #141e41;
    }
    .robot {
      width: 58px;
      height: 54px;
      padding-right: 8px;
    }
  }
  .content {
    p:nth-of-type(1) {
      margin-top: 10px;
      font-weight: 600;
      font-size: 18px;
      color: #141e41;
    }
    p:nth-of-type(2) {
      margin-top: 10px;
      font-weight: 500;
      font-size: 14px;
      color: #9ba1b6;
      line-height: 20px;
    }
    .loading-div {
      margin-top: 20px;
      width: 120px;
      height: 120px;
      background: rgba(53, 115, 244, 0.1);
      .loading-gif {
        width: 100px;
        height: 100px;
      }
    }
  }
}
</style>